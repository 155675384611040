import React from "react";
import './b1_profile_account.css'
import LayoutB1Profile from "../../components/layout-b1-profile"

export default class B1ProfileAccount extends React.Component{
  state={
    showdata:true,
    showPassword:false,
    password:'1245dnhgrs',
    phoneNum:'18388888887',
    editPhoneStep:1
  }
  editPassword=()=>{
    if(this.showPassword){

    }else {
      this.setState({
        showPassword:true
      })
    }
  }
  changePhoneNum=()=>{
    this.setState({
      editPhoneStep:this.state.editPhoneStep+1
    })
  }
  editPhoneNum=()=>{
    this.setState({
      editPhoneNum:true,
      showdata:false,
      editPhoneStep:1
    })
  }

  oldPhoneInput=(e)=>{
    console.log(e.target.value)
    this.setState({
      oldPhoneNum:e.target.value
    })
  }
  newPhoneInput=(e)=>{
    console.log(e.target.value)
    this.setState({
      phoneNum:e.target.value
    })
  }

  backToData=()=>{
    this.setState({
      showdata:true,
      editPhoneNum:false,
      editEmail:false
    })
  }

  editEmail=()=>{
    this.setState({
      showdata:false,
      editEmail:true,
      editEmailStep:1
    })
  }
  changeEmail=()=>{
    this.setState({
      editEmailStep:this.state.editEmailStep+1
    })
  }


  render() {
    return(
      <LayoutB1Profile location={this.props.location} >
        <div className="account-data">
          { this.state.showdata&&
          <div className="show-data">
            <div className="data-item">
              <div className="data-item-title">绑定手机号</div>
              <div className="data-item-content">{this.state.phoneNum}</div>
              <div className="data-item-edit" onClick={this.editPhoneNum}>修改</div>
            </div>
            <div className="data-item">
              <div className="data-item-title">邮箱</div>
              <div className="data-item-content">1888888@163.com</div>
              <div className="data-item-edit" onClick={this.editEmail}>修改</div>
            </div>
            <div className="data-item" style={{marginBottom:'0px'}}>
              <div className="data-item-title">登录密码</div>
              <div className="data-item-content">{this.state.showPassword?this.state.password:'**********'}</div>
              <div className="data-item-edit" onClick={this.editPassword}>{this.state.showPassword?'编辑':'查看'}</div>
            </div>
          </div>
          }


          {this.state.editPhoneNum&&
          <div className="editPhoneNum">
            <div style={{textAlign:'center'}}>重新绑定手机号</div>
            {
              this.state.editPhoneStep===1&&
              <div>
                <div className="old-phone-input">
                  <div className="phone-input-title">请输入旧手机号：</div>
                  <input type="text" onChange={this.oldPhoneInput}/>
                  <div className="empty"></div>
                </div>
                <div className="old-phone-verificationCode">
                  <div className="phone-input-title">请输入验证码：</div>
                  <input type="text" />
                  <div className="code-btn">验证码</div>
                </div>
                <div onClick={this.changePhoneNum} className="edit-bottom-btn">确定</div>
              </div>
            }
            {
              this.state.editPhoneStep===2&&
              <div >
                <div className="new-phone-input">
                  <div className="phone-input-title">请输入新手机号：</div>
                  <input type="text" onChange={this.newPhoneInput}/>
                </div>
                <div onClick={this.changePhoneNum} className="edit-bottom-btn">确定</div>
              </div>
            }
            {
              this.state.editPhoneStep===3&&
              <div>
                <div className="edit-success-text">
                  修改成功！
                </div>
                <div onClick={this.backToData} className="edit-bottom-btn">返回</div>
              </div>
            }

          </div>
          }

          {this.state.editEmail&&
          <div className="edit-email">
            <div className="edit-email-title">重置邮箱</div>
            {this.state.editEmailStep===1&&
            <div>
              <div className="new-email-input">
                <div>请输入新邮箱地址：</div>
                <input type="text"/>
              </div>
              <div onClick={this.changeEmail} className="edit-bottom-btn">确定</div>
            </div>
            }
            {this.state.editEmailStep===2&&
            <div>
              <div className="edit-success-text">
                邮件已发送至邮箱，请尽快去验证
              </div>
              <div onClick={this.changeEmail} className="edit-bottom-btn">去验证</div>
            </div>
            }
            {this.state.editEmailStep===3&&
            <div>
              <div className="edit-success-text">
                修改成功
              </div>
              <div onClick={this.backToData} className="edit-bottom-btn">返回</div>
            </div>
            }
          </div>

          }

        </div>
      </LayoutB1Profile>
    )
  }
}